import React, { useEffect } from 'react'
import './CashBack.scss'
import Button from './Button/Button'
import { scrollToSection } from '../../modules/utils'
import appGoogleTracking from '../../modules/GoogleTracking'
export default function Cashback(props) {


  const cachbacks = () => {
    if (props.count >= 2000) {
      return '2';
    } else if (props.count >= 1000) {
      return '1';
    } else if (props.count >= 500) {
      return '5';
    } else if (props.count >= 100) {
      return '4';
    } else if (props.count < 100) {
      return '3';
    }
  }

  return (
    <div className='containerCashback'>
      <h2>Diese Woche noch verfügbar:</h2>

      <div className='containerImage'>

        <picture>
          <source srcSet={`resources\\images\\alle\\bigcachbacks${cachbacks()}.png`} media="(min-width: 1024px)" />
          <source srcSet={`resources\\images\\alle\\bigcachbacks${cachbacks()}.png`} media="(min-width: 481px)" />
          <source srcSet={`resources\\images\\alle\\smallcachbacks${cachbacks()}.png`} media="(min-width: 200px)" />
          <img className='imageCashBack' src={`resources\\images\\alle\\bigcachbacks${cachbacks()}.png`} alt="cashback" />
        </picture>
      </div>

      <div className='containerDesc'>
        <p>
          Starte den Tag köstlich zart und genieße die Milka Haselnusscreme.
          So wird dein Frühstück zum besonderen Genussmoment.
          Die Milka Haselnusscreme mit echten Haselnüssen und feinem Kakao – als Aufstrich für deinen Frühstückstoast,
          als Topping für Pancakes oder direkt mit dem Löffel aus dem Glas.
          <br /><br />
          <bold style={{fontFamily: 'Good Headline Bold'}}> Jetzt ganz einfach testen</bold>: Milka Haselnusscreme sowie ein Toastbrot deiner Wahl kaufen, Kaufbeleg hochladen und du erhältst 2€ vom Kaufpreis zurück.
        </p>
      </div>
      <div className='containerBtn'>
        <Button onClick={() => {
          scrollToSection('formulaire');
          appGoogleTracking.dataLayerPush({
            'dataEvent': 'join_now',
            'dataCategory': 'Start Page',
            'dataAction': 'Click on Join Now',
            'dataLabel': 'GAME_SP_PART'
          })
        }}>JETZT MITMACHEN</Button>
      </div>
      <div className='terms'>
        <a href='/teilnahmebedingungen' onClick={() => {
          appGoogleTracking.dataLayerPush({
            'dataEvent': 'click_conditions_join_now',
            'dataCategory': 'Start Page',
            'dataAction': 'Click on Conditions',
            'dataLabel': 'ENG_SP_PART'
          })
        }}>*Teilnahmebedingungen der Aktion</a>
      </div>
    </div>
  )
}
