import React from 'react'
import './StepContent.scss'
export default function StepContent() {

    return (
        <div className='containerStepContent'>
            <h2 className='minititle'>So einfach kannst du Milka Haselnusscreme testen</h2>
            <div className='step'>
                <div className='step1'>
                    <img src="resources\images\alle\step1.png" alt="Step1" />
                    <p>Milka Haselnusscreme und Toastbrot kaufen.</p>
                </div>
                <div className='step2'>
                    <img src="resources\images\alle\step2.png" alt="Step2" />
                    <p>Kaufbeleg bis zum 29.09.2024 hier hochladen.</p>
                </div>
                <div className='step3'>
                    <img src="resources\images\alle\step3.png" alt="Step3" />
                    <p>2€ vom Kaufpreis erstattet bekommen.</p>
                </div>
            </div>
            
        </div>
    )
}
