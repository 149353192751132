import React, { useEffect, useState } from 'react';
import "./Card.scss";
import CardHolding from './CardHolding';

const Card = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    const now = new Date();

    let category = 'Announcement Page';
    let label = 'ENG_AP_OTHER';
    let label3 = 'GAME_AP_PART';
    if (props.campaignStatus === 'main') {
        category = 'Start Page';
        label = 'ENG_SP_OTHER';
        label3 = 'GAME_SP_PART';
    } else if (props.campaignStatus === 'end') {
        category = 'End Page';
        label = 'ENG_EP_OTHER';
        label3 = 'GAME_EP_PART';
    } else if (window.location.pathname === '/confirm') {
        category = 'Confirmation Page';
        label = 'ENG_CP_OTHER';
        label3 = 'GAME_CP_PART';
    }

    const tagging1 = {
        event: "subscribe_newsletter",
        category: category,
        action: "Go to Page",
        label: label
    };

    const tagging2 = {
        event: "go_to_create_song",
        category: category,
        action: "Go to Page",
        label: label
    };

    const tagging3 = {
        event: "join_now",
        category: category,
        action: "Click on Join Now",
        label: label3
    };



    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <div className="thank-you-content">

            <div className="thank-you">
                <CardHolding img={props.t('Page.Holding.card.1.img')} title={props.t('Page.Holding.card.1.title')} text={props.t('Page.Holding.card.1.text')} btn={props.t('Page.Holding.card.1.btn')} tagging={tagging1} link={props.t('Page.Holding.card.1.link')} />
                <CardHolding img={props.t('Page.Holding.card.2.img')} title={props.t('Page.Holding.card.2.title')} text={props.t('Page.Holding.card.2.text')} btn={props.t('Page.Holding.card.2.btn')} tagging={tagging2} link={props.t('Page.Holding.card.2.link')} margin={true} />
                {now < new Date('2024-08-31') ?
                    <CardHolding img={props.t('Page.Holding.card.3.img')} title={props.t('Page.Holding.card.3.title')} text={props.t('Page.Holding.card.3.text')} btn={props.t('Page.Holding.card.3.btn')} tagging={tagging3} link={props.t('Page.Holding.card.3.link')} />
                    :
                    <CardHolding img={props.t('Page.Holding.card.4.img')} title={props.t('Page.Holding.card.4.title')} text={props.t('Page.Holding.card.4.text')} btn={props.t('Page.Holding.card.4.btn')} tagging={tagging3} link={props.t('Page.Holding.card.4.link')} />
                }
            </div>
        </div>
    );
}

export default Card;
