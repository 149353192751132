import axios from "axios";

const campaign = () => {
    const _URL = "/api/campaigngate/status/fr-fr";
    let _SERVICE_URL = "";
    const isProd = "donut-muffin.milka.eu", hiddenProd = "proximitybbdo-donut-muffin-milka-eu";

    if (window.location.hostname.toLowerCase().indexOf(isProd) > -1) {
        _SERVICE_URL = "https://api.donut-muffin.milka.eu";
    } else if (window.location.hostname.toLowerCase().indexOf(hiddenProd) > -1) {
        _SERVICE_URL = "https://proximitybbdo-donut-muffin-milka-eu-api-v25ef3pzhq-ey.a.run.app"
    } else {
        _SERVICE_URL = "https://api-donut-muffin-milka-eu.mdlzapps.cloud"
    }

    var LoadCampaign = () => {
        var campaignURL = _URL,
            locale = window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1].length < "en-us".length + 1 ? window.location.pathname.split('/')[1] : "en-us";

        campaignURL += `?locale=${locale}`
        return axios({
            method: 'get',
            url: campaignURL
        })
    }

    const CampaignOreo = () => {
        const local = window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1].length < "de-de".length + 1 ? window.location.pathname.split('/')[1] : "";
        /**
            {
                "success": true,
                "message": null,
                "data": {
                    "status": "main", // REDIRECT, COUNTRY_NOT_PARTICIPATING, holding, main, end
                    "countryCode": "FR", // DE, AT, CH, GB, FR, BE, NL, LU, MU ... 
                    "locale": "fr-fr" // de-de, de-at, be-fr ...
                }
            }
        **/
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/CampaignStatusOreo/${local}`,
        })
    };
    const CampaignMilka = () => {
        const local = window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1].length < "de-de".length + 1 ? window.location.pathname.split('/')[1] : "";
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/CampaignStatusMilka/${local}`,
        })
    };


    const ServicesUrl = () => {
        var hostName = window.location.hostname,
            isProd = hostName.toLowerCase().indexOf('milka.de') > -1 ? true : false,
            isHiddenProd = hostName.toLowerCase().indexOf('proximitybbdo') > -1 ? true : false,
            isStage = hostName.toLowerCase().indexOf('mdlzapps.cloud') > -1 ? true : false;
        if (isProd) {
            return 'https://api.haselnusscreme.milka.de';
        }

        else if (isStage) {
            return 'https://api-haselnusscreme-milka-de.mdlzapps.cloud';
        }
        else if (isHiddenProd) {
            return 'https://proximitybbdo-haselnusscreme-milka-de-api-v25ef3pzhq-ey.a.run.app';
        }
        else {
            return 'https://api-haselnusscreme-milka-de.mdlzapps.cloud';
        }
    }
    const participation = ({ data }) => {
        // alert('test')
        let baseUrl = ServicesUrl();
        return axios({
            method: "POST",
            url: baseUrl + `/Participation/SubmitParticipation`,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    };
    const status = () => {
        let baseUrl = ServicesUrl();
        return axios({
            method: "GET",
            // url: campaignUrl + `/CampaignStatus` + retailer.replace(/^./, retailer[0].toUpperCase())
            url: baseUrl + `/CampaignStatusToast`
        })
    }



    return {
        status,
        LoadCampaign,
        CampaignOreo,
        participation, CampaignMilka

    }
}

var CampaignServices = campaign();
export default CampaignServices;