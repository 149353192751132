import React from "react";
import { withTranslation } from "react-i18next";
// import Aux from '../../hoc/Auxiliare';

import '../styles/Faq.scss';
import appGoogleTracking from "../../modules/GoogleTracking";
import { mainLoaderToggle } from "../../modules/Helpers";

class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        // setTimeout(() => {
        //     _SCROLL_PAGE.ScrollToTop();
        // }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-faqs" className="buzzer-game">
                {this.faqContent(this.props)}
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/icons/plus.png";
        const iconMinus = "/resources/icons/minus.png";
        return (
            <div className="sommer content-faq ">
                <div className="content-faq__item">
                    <h6>DARF ICH JEDES TOASTBROT KAUFEN?</h6>
                    <img src={iconMinus} className="content-faq__img notshown" 
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_1"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content show">
                        <p>Ja, jedes Toastbrot, das zusammen mit der Milka Haselnusscreme gekauft wird, ist ein teilnehmendes Aktionsprodukt.</p>
                    </div>
                </div>



                <div className="content-faq__item">
                    <h6>WO FINDE ICH DIE MILKA HASELNUSSCREME?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_2"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Milka Haselnusscreme ist bei einer Vielzahl von deutschen Lebensmitteleinzelhändlern und auch im Onlinehandel erhältlich. Die Verfügbarkeit kann je nach Händler variieren. Solltest du bei deinem Lebensmitteleinzelhändler keine Milka Haselnusscreme finden können, versuche es bitte bei einem weiteren Händler.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>WER DARF AN DER MILKA HASELNUSSCREME 2€ CASHBACK AKTION TEILNEHMEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_3"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Teilnahmeberechtigt sind ausschließlich natürliche Personen, die mindestens 18 Jahre alt sind und ihren Wohnsitz in der Bundesrepublik Deutschland haben sowie über ein Zahlungskonto in der Europäischen Union verfügen, das im SEPA-Zahlungsstandard erreichbar ist.</p>
                        <p>Mitarbeiter von Mondelez Deutschland und seinen Konzern- und Schwestergesellschaften sowie der eingebundenen Vertragspartner und ihrer unmittelbaren Familienangehörigen sind von einer Teilnahme an der Aktion ausgeschlossen.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WIE KANN ICH AN DER MILKA HASSELNUSSCREME 2€ CASHBACK AKTION TEILNEHMEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_4"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Durch den Erwerb eines Glases der Milka Haselnusscreme in Verbindung mit einer Packung Toastbrot deiner Wahl im Zeitraum vom 01.07.2024 bis zum 29.09.2024, sowie der anschließenden Einreichung eines gut lesbaren Kaufbelegs und der Kontodaten (Kontoinhaber sowie IBAN) über das Teilnahmeformular bis spätestens 29.09.2024 erhält der Teilnehmer innerhalb von 6 Wochen nach Einreichung eine Rückerstattung in Höhe von 2 €, welche auf das angegebene Konto überwiesen wird.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>BIS WANN KANN ICH AN DER MILKA HASSELNUSSCREME 2€ CASHBACK AKTION TEILNEHMEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_5"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Du kannst im Zeitraum vom 01.07.2024 bis zum 29.09.2024 an der Aktion teilnehmen.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WIE HÄUFIG KANN ICH AN DER MILKA HASSELNUSSCREME 2€ CASHBACK AKTION TEILNEHMEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_6"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Jeder Teilnehmer darf nur einmal mit einem gültigen Kaufbeleg, einer E-Mail-Adresse oder einer IBAN teilnehmen. Die Kontodaten werden überprüft, um sicherzustellen, dass sie eindeutig sind. Der Name des Teilnehmers muss zwingend mit dem Namen, der als Kontoinhaber genannt ist, übereinstimmen. Jeder Kaufbeleg darf nur einmal verwendet werden. Die Teilnahme ist limitiert auf einen Produktkauf pro Haushalt. Ein Haushalt definiert sich über die Übereinstimmung des Familiennamens und der Anschrift. Bei Mehrfachteilnahme wird nur der Kaufpreis für den ersten hochgeladenen Kaufbeleg erstattet; es besteht kein weiterer Erstattungsanspruch.  
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WIE HOCH IST DER MAXIMALE ERSTATTUNGSBETRAG?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_7"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Dem Teilnehmer werden 2€ für die gekauften Aktionsprodukte erstattet. 
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WELCHE INFORMATIONEN WERDEN AUF DEM KAUFBELEG BENÖTIGT? </h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_8"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Der Kaufbeleg muss den Händler, das Kaufdatum, die Kaufsumme, die Bon Nummer und die Aktionsprodukte (Milka Haselnusscreme und Toastbrot) deutlich lesbar enthalten. Unleserliche Belege werden nicht akzeptiert.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WIE WIRD DAS WÖCHENTLICHE KONTINGENT ÜBERWACHT? </h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_9"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Das wöchentliche Kontingent für Rückzahlungen wird auf der Website in Form eines Countdowns angezeigt. Sobald das Kontingent aufgebraucht ist, ist eine Teilnahme in der jeweiligen Woche nicht mehr möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>WANN GIBT ES EIN NEUES KONTINGENT AN ERSTATTUNGEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_10"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Der Countdown für das Kontingent wird jeden Montag um 9:00 neu gestartet.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>MUSS ICH DEN KAUFBELEG AUFBEWAHREN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Der Kaufbeleg (Original, keine Kopien) als Kaufnachweis ist bis zur Rückerstattung des Kaufpreises aufzubewahren und bei Anforderung vorzulegen.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>WANN ERHALTE ICH MEIN GELD ZURÜCK?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Die Rückerstattung der 2€ erfolgt innerhalb von ca. 6 Wochen nach erfolgreicher Bestätigung deiner Eingaben auf das angegebene Bankkonto.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>WAS KANN ICH TUN, WENN AUF MEINEM KAUFBELEG DIE PRODUKTE NICHT EINDEUTIG BEZEICHNET SIND?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Leider haben wir keinen Einfluss auf den Produkttext auf dem Kaufbeleg. Im Zweifelsfall nimm ein gemeinsames Foto von den Aktionsprodukten und dem Kaufbeleg auf.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>KANN ICH ONLINE EINKAUFEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Ja, Käufe bei Onlinehändlern sind teilnahmeberechtigt. Erstattet werden auch hier 2€. Etwaige Versand- oder Lieferkosten werden nicht erstattet. Retournierte Bestellungen sind von der Teilnahme ausgeschlossen.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>WO KANN ICH MICH BEI TECHNISCHEN PROBLEMEN ODER RÜCKFRAGEN ZUR MILKA HASSELNUSSCREME 2€ CASHBACK AKTION MELDEN?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Sämtliche Fragen, Kommentare oder Beschwerden zu der Milka Haselnuss 2€ Cashback Aktion sind an <a href="mailto:verbraucherservice@mdlz.com">Verbraucherservice@mdlz.com</a> zu richten. Fern mündlich mitgeteilte, oder verspätete Beschwerden können nicht bearbeitet werden. 
                        </p>
                    </div>
                </div>

            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if (classes.includes("notshown")) {
            e.target.setAttribute('src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
            appGoogleTracking.processEventCTANavLink(e);
        } else {
            e.target.setAttribute('src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }
}

export default withTranslation()(Faq);