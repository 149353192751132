//Milka Easter- some optimization was done from the previous project

const GLOBAL_CONFIG = {
  /* 
        Main Settings
    */
  Settings: {
    localDev: false, //Need to be false on staging and prod
    campaign: true, //True or false
    campaignStatus: 'main', //main, holding, end
    localDomain: 'localhost',
    defaultSite: 'alle', //budni, kaufland, edeka, netto, alle
    fileStatus: false,
    pageSection: '',
    innerLink: false,
    prizeAvailable: false
  },

  /*.

        URL for webservices
    */
  ServiceSettings: {
    //Webservices
    campaignURL: '/status',
    particationURL: '/participate',
  },

  /*
        URLs for the different page
  */
  Route: {
    home: '/', //no fig
    startMitmachen: '/startMitmachen', //ok
    end: '/', //no fig
    prize: '/gewinne', // prize ///(ok)
    participation: '/mitmachen', //particpation /////////////////mila atao kely2 ny sary refa porable
    gift: '/gift', //gift  //nononono
    thankyou: '/confirm', //Thank you ///////okok
    transfert: '/transfert', //Thank you transfert argent ///////okok
    product: '/produkte', //product /(nononono)
    faq: '/faq', //faq no figma
    terms: '/teilnahmebedingungen', //terms (no figma)
    cookie: '/cookie', //cookie
    spin: '/wheelspin', //cookie
  },

  /* 
        Google recaptcha config
    */
  Captcha: {
    siteKey: '6Lena10iAAAAAALY5qWFaMxHRlF0utRCJRTlim-A',
    language: 'de',
  },

  /*
        GTM ID : Google gtm scripts
    */
  GTM: {
    gtmTag: true, //true or false
    // gtmIDRewe: 'GTM-WXNN86L', //need to put the correct one - dev GTM-000000
    // gtmIDKaufland: 'GTM-M76THVK', //GTM-M76THVK
    // gtmIDMuller: 'GTM-N998ZM7',
  },

  Session: {
    userstatus: 'userstatus',
    userinfo: 'userinfo',
  },

  JSON: {
    alle: '/resources/localisation/alle_localisation.json',
    edeka: '/resources/localisation/edeka_localisation.json',
    kaufland: '/resources/localisation/kaufland_localisation.json',
    budni: '/resources/localisation/budni_localisation.json',

    rewe: '/resources/localisation/rewe_localisation.json',
    muller: '/resources/localisation/muller_localisation.json',
    netto: '/resources/localisation/netto_localisation.json',
  },

  Localisation: {
    path: '/resources/localisation/',
    version: 'K74036SQ97E1AF857WDF9S5D',
  },

  Date: {
    alle: {
      start: '21.02.2022',
      end: '16.04.2022',
    },
    edeka: {
      start: '01.02.2024',
      end: '01.05.2024',
    },
    kaufland: {
      start: '01.02.2024',
      end: '01.05.2024',
    },
    budni: {
      start: '01.02.2024',
      end: '01.05.2024',
    },
    rewe: {
      start: '21.02.2022',
      end: '16.04.2022',
    },
    muller: {
      start: '21.02.2022',
      end: '16.04.2022',
    },
    netto: {
      start: '18.10.2021',
      end: '24.12.2021',
    },
  },
}

export default GLOBAL_CONFIG
