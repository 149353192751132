import React, { Component } from 'react';
import { mainLoaderToggle } from '../../modules/Helpers';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import TitleContent from '../../components/TitleContent/TitleContent';
import Card from '../components/Card';

class End extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        };
        this.resize = this.resize.bind(this);
    }


    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        mainLoaderToggle('hide');
    };

    render() {
        const { t,campaignStatus } = this.props;

        return (
            <div>
                <TitleContent
                    heading={parse(t('Page.End.title'))}
                    subheading={t('Page.End.subTitle')}
                />
                <Card t={t} campaignStatus={campaignStatus}></Card>
            </div>
        )
    }

}
export default withTranslation()(End);