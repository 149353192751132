import React, { Component } from 'react';
import '../styles/Holding.scss';
import '../styles/StartMitmachen.scss';
import { mainLoaderToggle } from '../../modules/Helpers';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import TitleContent from '../../components/TitleContent/TitleContent';
import Cashback from '../components/Cashback';
import StepContent from '../components/StepContent';
import Formulaire from '../components/Formulaire';
import Card from '../components/Card';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';


class StartMitmachen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            // countLocal : 0,
            message :'' 
        };
        this.resize = this.resize.bind(this);
    }
    

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        // Fonction pour récupérer les paramètres d'URL
        // const urlParams = new URLSearchParams(window.location.search);
        // const setcount = urlParams.get('setcount');
        // this.setState({countLocal : setcount})
        // this.setState({ message: `In dieser Woche kannst du nicht mehr teilnehmen, da das Kontingent ausgeschöpft ist. Und nicht vergessen: Montag, ${this.props.message} ab 10 Uhr hast du wieder die Chance, 2€ Cashback  zu erhalten.Schnell sein lohnt sich – hol dir deinen Cashback!`})
        // alert(`La valeur de setcount est : ${setcount}`);
        window.scrollTo(0, 0);
        mainLoaderToggle('hide');
    };

    render() {
        const { t, count } = this.props;

        return (
            <div>
                {
                count <= 0 ?
                    // this.state.countLocal<=0 ?
                    <TitleContent
                        heading={parse(t('Page.StartMitmachen.title'))}
                        subheading={this.state.message}
                    />
                    :
                    <TitleContent
                        heading={parse(t('Page.Participation.title'))}
                        subheading={t('Page.Participation.subTitle')}
                    />
                }


                <div className='image'>
                    <picture >
                        <source srcSet="resources\images\alle\smallProduct.png" media="(max-width: 768px)" />
                        <img src="resources\images\alle\bigProduct.png" alt="Description of your image" />
                    </picture>
                </div>

                {
                    count <= 0 ?
                    // this.state.countLocal <= 0 ?
                        <Card t={t} campaignStatus={this.props.campaignStatus}></Card>
                        :
                        <>
                            <div>
                                {/* <Cashback count={this.state.countLocal}></Cashback> */}
                                <Cashback count={count}></Cashback>
                            </div>
                            <div>
                                <StepContent />
                            </div>
                            <hr className='divider' />
                            <div>
                                <Route render={(props) => <Formulaire {...props} />} />
                            </div>
                        </>
                }


            </div>
        )
    }

}
export default withTranslation()(StartMitmachen);