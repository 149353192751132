import React, { useEffect, useRef, useState } from 'react'
import './Button.scss'
import appGoogleTracking from '../../../modules/GoogleTracking';
export default function Button(props) {
    //state to manage file UX
    const fileInputRef = useRef(null);
    const [returnMessageUpload, setreturnMessageUpload] = useState('');

    useEffect(() => {
        // alert(props.type);
    }, []);


    const handleClickFile = () => {
        fileInputRef.current.click();
        appGoogleTracking.dataLayerPush({
            'dataEvent': 'choose_file',
            'dataCategory': 'Start Page',
            'dataAction': 'Click on Choose A File',
            'dataLabel': 'GAME_SP_PART'
        })
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const importFileDiv = document.getElementById('importFile');
        const returnMessage = document.getElementById('returnMessageUpload');

        if (file) {

            importFileDiv.classList.add('success');

            const allowedTypes = ['image/jpg', 'image/jpeg', , 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                setreturnMessageUpload("Ungültiges Dateiformat. Bitte nur gültiges Format hochladen.");
                returnMessage.style.color = 'red';
                importFileDiv.classList.remove('success');
                importFileDiv.classList.add('notvalid');

                return;
            }

            // Vérifier la taille du fichier (exemple : maximum 2 Mo)
            if (file.size > 10 * 1024 * 1024) {

                setreturnMessageUpload("Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten.");
                returnMessage.style.color = 'red';
                importFileDiv.classList.remove('success');
                importFileDiv.classList.add('notvalid');
                return;
            }

            //SUCCESS
            setreturnMessageUpload(file.name);
            importFileDiv.classList.remove('notvalid');
            returnMessage.style.color = 'rgb(129 185 41 / 97%)';
            props.getFile(file);
        }

    }

    function getButton() {
        if (props.type === 'submit') {
            return (
                <button className='_btn' onClick={props.onClick} type='submit'>
                    <p className='content_Btn'>
                        {props.children}
                    </p>
                </button>
            )
        }
        else if (props.type === 'file') {
            return (
                <>
                    <input
                        type="file" id='file' className="custom-input" placeholder="Vorname*" data-require='true' data-type="text" data-error-target='#error-file' data-required-message="Du hast noch keinen Kaufbeleg hochgeladen. Wähle die entsprechende Datei aus und lade deinen Kaufbeleg hoch. Bitte denke daran, die nicht an der Aktion teilnehmenden Artikel auf dem Kaufbeleg unkenntlich zu machen." data-event-fieldname="FirstName"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <div id='importFile' className='_btn' onClick={handleClickFile} type='file'>
                        <p className='content_Btn'>
                            {props.children}
                        </p>
                    </div >
                    {/* no file uploaded */}
                    <p className="para">Du kannst deinen Kaufbeleg als .jpg oder .pdf hochladen.</p>
                    <span style={{ marginTop: '10px', maxWidth: '400px' }} id="error-file" className="form-input__error active"></span>
                    <p id='returnMessageUpload' style={{ marginTop: '10px', fontFamily: 'Good Headline Pro' }}>{returnMessageUpload}</p>
                </>
            )
        }
        else {
            return (
                <div className='_btn' onClick={props.onClick}>
                    <p className='content_Btn'>
                        {props.children}
                    </p>
                </div>
            )

        }

    }

    return (
        <>
            {getButton()}
        </>
    )
}
