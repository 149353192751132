const _LOCAL_CAPTCHA = {
    // local: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
    local: "6LdkRKYnAAAAAF6HB1BV-PrOXbHlnc8_aNvVy9aN",

    HNC: {
        key: "6Le-dtUpAAAAAKvjJQ_t0m87tcuJeS72aJZ_CPRS",
        lang: "de"
    }
}

export default _LOCAL_CAPTCHA;