import { Component } from "react";
import React from 'react'
import { withTranslation } from "react-i18next";
import './Formulaire.scss';
import Button from "./Button/Button";
import ValidateForm from '../../modules/Validate';
import { getValuePurchaseAmount, getValueRetrailer, getValuesSalutation, scrollToError, serverDateDMY, transformDateFormat, validateAge } from "../../modules/utils";
import 'flatpickr/dist/flatpickr.css';
import flatpickr from "flatpickr";
import ReCaptcha from 'react-google-invisible-recaptcha';
import _LOCAL_CAPTCHA from '../../Models/Captcha';
import CampaignServices from '../../Services/Campaign';
import appGoogleTracking from "../../modules/GoogleTracking";
import Popup from "../../components/popup/popup";

const Salutations = {
    _MALE: "Herr",
    _FEMALE: "Frau",
    _OTHERS: "Divers",
    dropdown: {
        "": "Anrede*",
        Herr: "Herr",
        Frau: "Frau",
        Divers: "Divers",
    }
}

const retrailer = {
    dropdown: {
        "": "Händler-Angabe",
        REWE: "REWE",
        Edeka: "Edeka",
        Nahkauf :"Nahkauf",
        Trink_Spar:"Trink & Spar",
        AEZ:"AEZ",
        aktiv_irma:"aktiv & irma",
        akzenta:"akzenta",
        Aldi_Nord:"Aldi Nord",
        Aldi_Süd: "Aldi Süd",
        Budnikowsky: "Budnikowsky",
        Citti_Märkte: "Citti Märkte ",
        Combi: "Combi",
        Diska: "Diska",
        dm: "dm",
        E_center : "E-center",
        EDEKA: "EDEKA",
        Elli: "Elli",
        famil_Bartels_Langness: "famila Bartels Langness",
        famila_Bünting: "famila Bünting",
        Feneberg: "Feneberg",
        Globus: "Globus",
        Globus_Haus_Halle: "Globus Haus Halle",
        Herkules: "Herkules",
        Hit: "Hit",
        Ihre_Kette_Extra_Markt: "Ihre Kette Extra Markt",
        Jibi: "Jibi",
        Kaufland: "Kaufland",
        Kaufmarkt: "Kaufmarkt",
        Klaas_Kock : "Klaas+Kock",
        Konsum_Leipzig: "Konsum Leipzig",
        Lidl: "Lidl",
        Limbach: "Limbach",
        Markant_Märkte_Bartels_Langness: "Markant Märkte Bartels Langness",
        Markant_Märkte_Bünting: "Markant Märkte Bünting",
        Marktkauf: "Marktkauf",
        Müller: "Müller",
        Multi: "Multi",
        nah_und_gut: "nah und gut",
        Netto_Marken_Discount_Süd : "Netto Marken-Discount / Süd",
        Netto_Aps_Co_KG: "Netto Aps & Co. KG",
        NP_discount : "NP-discount",
        Penny: "Penny",
        Rewe: "Rewe",
        Rossmann: "Rossmann",
        SBK: "SBK",
        SBK_Compact: "SBK Compact",
        Sonstige: "Sonstige",
        Tegut: "Tegut",
        Treff3000: "Treff3000",
        V_Markt : "V-Markt",
        Vorteil_Center : "Vorteil-Center",
        Wasgau: "Wasgau",
        WEZ_EDEKA_Partner: "WEZ (EDEKA Partner)"
    }
}

/* const purchaseAmount = {
    dropdown: {
        "": "Preisangabe in €",
        _1: 1,
        _2: 2,
        _3: 3,
    }
} */
class Formulaire extends Component {

    state = {
        file: null,
        defaultDate: null,
        moreTerms: false,
        loaderSubmit: false,
        showPopup: false,
        errorMessage: null,
        errorMail:false
    }
    componentDidMount() {

        let receiptDateSelector = document.getElementById('receipt_date');

        //Date picker for purchase receipt date
        flatpickr(receiptDateSelector, {
            dateFormat: 'd.m.Y',
            minDate: '28.06.24',
            maxDate: 'today',
            disableMobile: true,
            default: this.state.defaultDate,
        });

        ValidateForm.validateField('frm_participation');
    }

    closePopup = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    }

    OnCaptchaError() {
        this._CAPTCHA.reset();
        this.setState({
            errorMessage: "CAPTCHA ERROR",
            showPopup: true
        })
    }

    onSubmitHandler(event) {
        event.preventDefault();
        let validateForm = ValidateForm.validateForm('frm_participation');
        if (validateForm && this.state.file) {
            //trigger captcha
            if (this._CAPTCHA.getResponse() !== '') {
                this._CAPTCHA.reset();
            } else {
                this._CAPTCHA.execute();

                // this.setState({
                //     errorMessage: "CAPTCHA ERROR",
                //     showPopup: true
                // })
            }
        }
        else {
            scrollToError();
        }
    }
    //When captcha is good -> Callback when Google capture is good
    onResolved = (token) => {
        this.ProcessFormData(token);
    }

    verifyEmail(){
        if (this._DETAILS_email.value.includes('+')){
            const m=document.getElementById('error-email');
            m.value='';
            this.setState({
                errorMail: true
            })
        }
    }

    ProcessFormData(token) {
        this.setState({
            loaderSubmit: true,
        });
        let data = {
            //number no alefa 
            salutation: getValuesSalutation(this._DETAILS_salutation.value),
            firstname: this._DETAILS_firstName.value,
            lastname: this._DETAILS_lastName.value,
            email: this._DETAILS_email.value,
            DOB: serverDateDMY(
                this._DETAILS_Birthday_day.value,
                this._DETAILS_Birthday_month.value,
                this._DETAILS_Birthday_year.value
            ),
            fullname: this._DETAILS_firstName.value + ' ' + this._DETAILS_lastName.value,
            Iban: this._DETAILS_iban.value,
            retailer: getValueRetrailer(this._DETAILS_retailer.value),
            purchaseDate: transformDateFormat(this._DETAILS_purchaseDate.value),
            purchaseAmount: this._DETAILS_purchaseAmount.value,
            newsletter: this._DETAILS_News.checked ? "1" : "0",
            privacyPolicy: this._DETAILS_privacyPolicy.checked ? "1" : "0",
            file: this.state.file,
            recaptcha: token
        };

        // console.log('data=========>', data);
        let formData = new FormData();

        // Append data to FormData
        for (let key in data) {
            formData.append(key, data[key]);
        }

        CampaignServices.participation({ data: formData })
            .then((response) => {
                let { success, data } = response.data;
                console.log('response.data', response.data);
                if (response.data.success) {
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'get_money_back',
                        'dataCategory': 'Start Page',
                        'dataAction': 'Click on Get Money Back',
                        'dataLabel': 'GAME_SP_PART'
                    });
                    this.props.history.push('/confirm');
                } else {
                    let errorStatus = data.Error[0];
                    console.log('test',data.Error[0]);
                    if (data.Error && errorStatus.includes('Email')) {
                        this.setState({
                          errorMail:true
                        })
                    }
                    // this.setState({
                    //     errorMessage: errorStatus,
                    //     showPopup: true
                    // })
                }
            })
            .catch((error) => {
                console.log('errorSubmit', error);
                this.setState({
                    errorMessage: "SERVER ERROR",
                    showPopup: true
                })
            })
            .finally(() => {
                this._CAPTCHA.reset();
                this.setState({
                    loaderSubmit: false,
                });
            });


    }
    getFile(file) {
        this.setState({ file: file })
    }
    render() {
        let _captcha = _LOCAL_CAPTCHA['HNC'];
        return (
            <div id='formulaire' className="formulaire">
                {
                    this.state.loaderSubmit ?
                        <div className="simple-loader__container active">
                            <div className="simple-loader__indicator"></div>
                        </div>
                        : null
                }
                {
                    this.state.showPopup ?
                        <Popup closePopup={this.closePopup} errorText={this.state.errorMessage}></Popup>
                        : null
                }
                <form
                    id="frm_participation"
                    name="frm_participation"
                    action="/"
                    method="post"
                    onSubmit={this.onSubmitHandler.bind(this)}
                    noValidate>
                    <div className="form-container centered">
                        <ReCaptcha
                            style={{ zIndex: "1000" }}
                            ref={ref => this._CAPTCHA = ref}
                            locale={_captcha.lang}
                            sitekey={_captcha.key}
                            onResolved={this.onResolved}
                            onErrored={this.OnCaptchaError}
                        />
                    </div>

                    <div className="upload">
                        <h2 className="title">Kaufbeleg hochladen</h2>
                        <img src="resources\images\upload-receipt-imgage.png" alt="image import" />
                        <Button getFile={(file) => this.getFile(file)} type='file'>Datei Auswählen</Button>

                    </div>

                    <div className="corps">
                        <div className="title">
                            <h2>Trage hier deine persönlichen Daten ein</h2>
                            <p>So kann Milka dich kontaktieren und dir die 2€ Cashback überweisen.</p>
                        </div>
                        <div className="champ">
                            <div id='salutation' className="default salutation">
                                <p>Pflichtfeld*</p>
                                <select style={{ fontFamily: "Good Headline Pro" }} ref={select => this._DETAILS_salutation = select} className="custom-input form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="salutation"
                                    id="salutation"
                                    placeholder="Anrede*"
                                    data-require='true'
                                    data-type="text"
                                    data-error-target='#error-salutation'
                                    data-required-message=""
                                    data-event-fieldname="Salutation"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Salutation"
                                    data-label="Type In">
                                    {
                                        Object.keys(Salutations.dropdown).map(key => {
                                            var i = 0;
                                            return <option style={{ color: "#3B2774" }} value={key} key={`${i++}-${key}`} >{Salutations.dropdown[key]}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div id="firstName" className="default">
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="Vorname*"
                                    data-require='true'
                                    data-type="text"
                                    id='firstName'
                                    data-error-target='#error-firstName'
                                    data-required-message=""
                                    data-event-fieldname="FirstName"
                                    ref={input => this._DETAILS_firstName = input} />
                            </div>
                            <div id='lastName' className="default">
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="Nachname*"
                                    data-require='true'
                                    data-type="text"
                                    id='lastName'
                                    data-error-target='#error-lastName'
                                    data-required-message=""
                                    data-event-fieldname="LastName"
                                    ref={input => this._DETAILS_lastName = input} />
                            </div>
                            <div id="email" className="default ligne">
                                <input
                                    type="email"
                                    className="custom-input"
                                    placeholder="E-Mail*"
                                    data-require='true'
                                    id='email'
                                    data-type="regex"
                                    data-regex-pattern="^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    data-error-target='#error-email'
                                    // data-pattern-message="Ups, da hat sich wohl ein Fehler bei deiner IBAN eingeschlichen. Bitte überprüfe deine IBAN."
                                    data-pattern-message="Ups, da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                                    data-event-fieldname="Email"
                                    ref={input => this._DETAILS_email = input} 
                                    onChange={()=>{
                                        this.setState({
                                            errorMail: false
                                        })
                                    }}
                                    onBlur={() => {this.verifyEmail()}}
                                    
                                    />
                                {this.state.errorMail ? <span className="form-input__error active">Ungültiges E - Mail Format.</span> : <span id="error-email" className="form-input__error active"></span>}
                            </div>
                            <div className="default">
                                <p>Land*</p>
                                <select name="" id="" className="custom-input select" data-require='true'>
                                    <option value="Deutschland" >Deutschland</option>
                                </select>
                            </div>
                            <div className="default">
                                <p>Geburtsdatum*</p>
                                <div id="container-birthday">
                                    <input
                                        type="text"
                                        className="custom-input date-input"
                                        placeholder="TT"
                                        data-require='true'
                                        data-type="regex"
                                        data-regex-pattern="^(?!00$)(3[01]|[0-2][0-9]|[1-9])$"
                                        id='days'
                                        data-error-target='#error-days'
                                        data-required-message=""
                                        data-event-fieldname="Days"
                                        ref={input => this._DETAILS_Birthday_day = input}
                                        onBlur={(e) => { validateAge(e) }}
                                    />
                                    <input
                                        id='month'
                                        type="text"
                                        className="custom-input date-input"
                                        placeholder="MM"
                                        data-require='true'
                                        data-type="regex"
                                        data-regex-pattern="^(0[1-9]|1[0-2]|[1-9])$"
                                        data-error-target='#error-mounth'
                                        data-required-message=""
                                        data-event-fieldname="Mounth"
                                        ref={input => this._DETAILS_Birthday_month = input}
                                        onBlur={(e) => { validateAge(e) }}
                                    />
                                    <input
                                        id='year'
                                        type="text"
                                        className="custom-input year-input"
                                        placeholder="JJJJ"
                                        data-require='true'
                                        data-type="regex"
                                        data-regex-pattern="^([0-9]{4})$"
                                        data-error-target='#error-year'
                                        data-required-message=""
                                        data-event-fieldname="Year"
                                        ref={input => this._DETAILS_Birthday_year = input}
                                        onBlur={(e) => { validateAge(e) }}
                                    />
                                </div>
                                <span id="error-birth" className="form-input__error active displayerror">Um teilnehmen zu können, müssen Sie 18 Jahre alt sein</span>
                            </div>

                            <h5 className="center">Deine Kontodaten</h5>
                            <div id="compte" className="default">
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="Kontoinhaber*"
                                    data-require='true'
                                    data-type="text"
                                    id='compte'
                                    data-error-target='#error-compte'
                                    data-required-message=""
                                    data-event-fieldname="Compte" />
                            </div>
                            <div id="compte2" className="default">
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="IBAN*"
                                    data-require='true'
                                    data-type="regex"
                                    id='compte2'
                                    data-regex-pattern="^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28}|UA\d{8}[A-Za-z0-9]{19})$"
                                    data-error-target='#error-compte2'
                                    data-pattern-message="Hoppla, die IBAN scheint nicht gültig zu sein. Bitte gib eine gültige IBAN ein."
                                    data-event-fieldname="Compte2"
                                    ref={input => this._DETAILS_iban = input} />
                                <span id="error-compte2" className="form-input__error active"></span>
                            </div>

                            <h5>Wo hast du die Milka Haselnusscreme gekauft?*</h5>
                            <div id="achat" className="default ligne">
                                <select
                                    ref={input => this._DETAILS_retailer = input}
                                    name=""
                                    className="custom-input select"
                                    data-require='true'
                                    data-type="text"
                                    id='achat'
                                    data-error-target='#error-achat'
                                    data-required-message=""
                                    data-event-fieldname="Achat">
                                    {
                                        Object.keys(retrailer.dropdown).map(key => {
                                            var i = 0;
                                            return <option style={{ color: "#3B2774" }} value={key} key={`${i++}-${key}`} >{retrailer.dropdown[key]}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <h5>Wann hast du die Milka Haselnusscreme gekauft?*</h5>
                            <div id="date" className="default ligne">

                                <input className="form-input__input js-event-type-in js-event-ab custom-input" type="text" name="receipt_date" id="receipt_date" placeholder="TT.MM.JJJJ" autoComplete="off" readOnly data-require="true" data-type="text" data-error-target="#error-receipt_date" data-required-message="Receipt date is required." data-pattern-message="Receipt date is not valid." data-event-fieldname="Receipt date" data-event="form-click" data-category="Form Action" data-action="Receipt date" data-label="Type In"
                                    ref={input => this._DETAILS_purchaseDate = input}
                                />
                            </div>

                            <h5>Wie teuer war die Milka Haselnusscreme?*</h5>
                            <div id="creme" className="default ligne">
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="Preisangabe in €"
                                    data-require='true'
                                    data-type="regex"
                                    id='creme'
                                    data-regex-pattern="^[0-9]+(\,[0-9]{1,3})?$"
                                    data-error-target='#error-creme'
                                    data-pattern-message=""
                                    data-event-fieldname="Compte2"
                                    ref={input => this._DETAILS_purchaseAmount = input} />
                                <span id="error-creme" className="form-input__error active"></span>
                                
                            </div>

                        </div>
                        <div className="terms">
                            <div>
                                <label className="custom-checkbox custom-inputs" htmlFor="terms">
                                    <input
                                        type="checkbox" id="terms" name="terms" className="js-event-type-in js-event-ab" data-error-target='#error-terms' data-require="true" data-type="checkbox" data-event-fieldname="Terms" data-required-message='Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren.'
                                        ref={check => this._DETAILS_privacyPolicy = check}
                                        onChange={() => {
                                            appGoogleTracking.dataLayerPush({
                                                'dataEvent': 'click_conditions_join_now',
                                                'dataCategory': 'Start Page',
                                                'dataAction': 'Click on Conditions',
                                                'dataLabel': 'ENG_SP_PART'
                                            })
                                        }}
                                    />
                                    <span className="checkbox-mark"></span>
                                    <span className="para">Ich habe die <a href="/teilnahmebedingungen" onClick={() => {
                                        appGoogleTracking.dataLayerPush({
                                            'dataEvent': 'click_conditions_register_now',
                                            'dataCategory': 'Start Page',
                                            'dataAction': 'Click on Conditions',
                                            'dataLabel': 'ENG_SP_PART'
                                        })
                                    }}>Teilnahmebedingungen</a> gelesen und akzeptiert.*
                                        <br />
                                        <span style={{ fontFamily: "Good Headline Pro" }} id="error-terms" className="form-input__error active"></span>
                                    </span>
                                </label>
                                {
                                    this.state.moreTerms ?
                                        <label className="moreTerms">Wir schätzen dein Vertrauen in unseren Umgang mit deinen personenbezogenen
                                            Daten. Verantwortliche Stelle für die Verarbeitung deiner personenbezogenen
                                            Daten im Rahmen dieser Aktion ist die Mondelēz Deutschland GmbH. Wir
                                            speichern personenbezogene Daten von dir nach Maßgabe der rechtlichen
                                            Vorschriften und ausschließlich zum Zweck der Abwicklung dieser Aktion
                                            (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. f) der Datenschutz-Grundverordnung EU
                                            2016/679). Es werden die E-Mail-Adresse, Vor- und Nachname sowie das
                                            Geburtsdatum und die Adressdaten der Teilnehmer erfasst und ausschließlich zur
                                            Verifizierung der Teilnahme gespeichert. Name und Adressdaten werden für den
                                            Versand der Gewinne verarbeitet (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b) der
                                            Datenschutz-Grundverordnung EU 2016/679, DS-GVO). Weitere Informationen zu
                                            dem Umgang mit deinen personenbezogenen Daten im Rahmen dieser Aktion
                                            findest du in unseren <a href="/teilnahmebedingungen" style={{ color: '#3B2774', textDecoration: 'underline' }} onClick={() => {
                                                appGoogleTracking.dataLayerPush({
                                                    'dataEvent': 'click_conditions_register_now',
                                                    'dataCategory': 'Start Page',
                                                    'dataAction': 'Click on Conditions',
                                                    'dataLabel': 'ENG_SP_PART'
                                                })
                                            }}>Teilnahmebedingungen.</a></label>
                                        :
                                        <label className="more"><strong onClick={(e) => {
                                            this.setState({ moreTerms: true });
                                            appGoogleTracking.dataLayerPush({
                                                'dataEvent': 'more_information',
                                                'dataCategory': 'Start Page',
                                                'dataAction': 'Click on More Information',
                                                'dataLabel': 'ENG_SP_PART'
                                            })
                                        }} >„Mehr Informationen {'>>'}“</strong></label>
                                }
                            </div>
                            <div>
                                <label className="custom-checkbox">
                                    <input
                                        type="checkbox" id="terms" name="terms" className="js-event-type-in js-event-ab" data-require="true"
                                        ref={check => this._DETAILS_News = check}
                                        onChange={() => {
                                            appGoogleTracking.dataLayerPush({
                                                'dataEvent': 'checkbox_conditions',
                                                'dataCategory': 'Start Page',
                                                'dataAction': 'Click on Checkbox',
                                                'dataLabel': 'GAME_SP_PART'
                                            })
                                        }}
                                    />
                                    <span className="checkbox-mark"></span>
                                    <p className="para">Ich möchte den regelmäßigen Newsletter mit zukünftigen Neuigkeiten, Gewinnspielen und Angeboten von Milka per E-Mail erhalten. Damit die Kommunikation noch besser auf meine Bedürfnisse abgestimmt werden kann, bin ich neben der Anmeldung für den Newsletter auch damit einverstanden, dass meine Daten inklusive meiner verschlüsselten E-Mail-Adresse für Folgendes verwendet werden: Bildung von Profilen, Analyse-Zwecke, Verknüpfung und Anreicherung mit Browserdaten sowie auf mich abgestimmte Werbung. Zu diesen Zwecken dürfen meine Daten von Milka auch an weitere Tochtermarken von Mondelēz International (Oreo, Philadelphia, TUC, Miracel Whip u. a.), Partner, Dienstleister oder Plattformen Dritter weitergegeben werden, welche die Daten ausschließlich für Mondelez International nach deren Weisung verarbeiten. Wir schätzen dein Vertrauen, wenn du deine persönlichen Daten mit uns teilst. Wir behandeln deine Daten stets fair und respektvoll, beschränkt auf den oben genannten Zweck. Wenn du mehr darüber erfahren möchtest, wie wir mit deinen Daten umgehen, lies bitte unsere <a target="_blank" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" onClick={() => {
                                        appGoogleTracking.dataLayerPush({
                                            'dataEvent': 'data_protection',
                                            'dataCategory': 'Start Page',
                                            'dataAction': 'Click on Data Protection',
                                            'dataLabel': 'ENG_SP_PART'
                                        })
                                    }}>Datenschutzerklärung</a>.</p>
                                </label>
                            </div>

                        </div>

                        <div className="submit">
                            <Button type='submit'> Geld zurückerhalten</Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}

export default withTranslation()(Formulaire);