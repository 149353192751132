export function scrollToSection(sectionName) {
    const targetElement = document.getElementById(sectionName);

    if (targetElement) {
        window.scrollTo({
            top: targetElement.offsetTop - ( // Optional offset for header/navbar height
                document.querySelector('header')?.offsetHeight || 0
            ),
            behavior: 'smooth',
        });
    } else {
        console.warn(`Element with ID "${sectionName}" not found for scrolling.`);
    }
};

export function scrollToError() {
    const targetElements = document.querySelectorAll('.notvalid');

    if (targetElements.length === 0) {
        console.log('No elements with class "notvalid" found.');
        return;
    }

    const targetElement = targetElements[0];
    console.log('Found element:', targetElement);

    // Temporarily display the element to get its correct dimensions
    const previousDisplay = targetElement.style.display;
    targetElement.style.display = 'block';
    targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
    });
    targetElement.style.display = previousDisplay;
};

function calculateAge(birthDateObj) {
    const today = new Date();

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    // Si la date de naissance n'est pas encore passée cette année, on soustrait 1 de l'âge
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
}

async function isNumber(...n) {
    for (let i = 0; i < n.length; i++) {
        if (Number(n[i]) != n[i]) {
            return false;
        }
    }
    return true;
}

export function validateAge(e) {
    const day = document.querySelector("input#days"),
        month = document.querySelector("input#month"),
        year = document.querySelector("input#year"),
        container = document.getElementById("container-birthday"),
        error = document.getElementById("error-birth");
    if (day && month && year) {
        const dayValue = day.value,
            monthValue = month.value,
            yearValue = year.value;
        if (dayValue && monthValue && yearValue) {
            const birthday = new Date(yearValue, monthValue, dayValue);
            const age = calculateAge(birthday);
            if (age < 18 || age > 100) {
                container.classList.add("agenotvalid");
                error.classList.remove("displayerror");
            }
            else {
                container.classList.remove("agenotvalid");
                error.classList.add("displayerror");
            }
        }
    }

}

export function checkMinimumAge(e, regex) {
    if (!e.target.value.match(regex)) {
        e.target.classList.remove("valid");
        e.target.classList.add("notvalid");
    } else {
        e.target.classList.remove("notvalid");
        e.target.classList.add("valid");
    }
    const day = document.querySelector("input#days"),
        month = document.querySelector("input#month"),
        year = document.querySelector("input#year");
    if (day && month && year) {
        const dayValue = day.value,
            monthValue = month.value,
            yearValue = year.value;
        if (dayValue && monthValue && yearValue) {
            const birthday = new Date(yearValue, monthValue - 1, dayValue);
            const age = calculateAge(birthday);
            isNumber(dayValue, monthValue, yearValue).then((val) => {
                let isTTOk = true;
                let isMMOk = true;
                if (dayValue > 31) {
                    isTTOk = false;
                }
                if (monthValue > 12) {
                    isMMOk = false;
                }
                if (!val || age < 18 || age > 100 || isTTOk == false || isMMOk == false) {
                    day.classList.remove("valid");
                    month.classList.remove("valid");
                    year.classList.remove("valid");

                    day.classList.add("notvalid");
                    month.classList.add("notvalid");
                    year.classList.add("notvalid");
                } else {
                    day.classList.remove("notvalid");
                    month.classList.remove("notvalid");
                    year.classList.remove("notvalid");

                    day.classList.add("valid");
                    month.classList.add("valid");
                    year.classList.add("valid");

                }
            });
        }
    }
}

export function serverDateDMY(day, month, year) {
    return year + '-' + month + '-' + day;
}
export function transformDateFormat(dateStr) {
    const [day, month, year] = dateStr.split('.');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}
export function getValuesSalutation(values) {
    switch (values) {
        case "Herr":
            return 1;
        case "Frau":
            return 2;
        case "Divers":
            return 10;
        default: return null
    }
}

export function getValuePurchaseAmount(values) {
    switch (values) {
        case "_1":
            return "1";
        case "_2":
            return "2";
        case "_3":
            return "3";
        default: return null
    }
}

export function getValueRetrailer(values) {
    switch (values) {
        case "Händler_Angabe":
            return "Händler-Angabe";
        default: return values
    }
}