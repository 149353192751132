import React, { Component, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider, withTranslation } from 'react-i18next'
import i18next, { buildLocalisedContent } from './modules/Localisation'
import Layout from './layout/Layout'
import './styles/_config.scss'
import CampaignServices from './Services/Campaign'

window.URL_PREFIX = null
window.COUNTRY_CODE = null
window.EndDate = null
window.DayLeft = null
window.FILE_STATUS = false
class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      campaign: true,
      campaignStatus: 'holding',
      localDev: false,
      localDomain: 'localhost',
      urlPrefix: '',
      countryCode: 'us',
      count: 0,
      message:''
    }
  }

  //Function to load promotional campaign
  //On each page load this function execute to get the promotional campaign status : main, holding or end
  loadCampaign = () => {

    //Ajax call to get the promotional state
    CampaignServices.status()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.success === true) {
            window.PROMOTION = response.data.status;	//Get promotional state : main, holding or end
            this.setState({
              campaign: true,
              campaignStatus: response.data.status,
              count: response.data.count,
              message: response.data.message
              //count: 1000,
              //campaignStatus: 'main',
            });

            buildLocalisedContent()


            //Set winner display behaviour - placeholder to know what to display on thank you page
            //Value DEFAULT, BANNER_ONE and BANNER_TWO

          } else {
            console.log('Error in response');
          }
        } else {
          console.log('Error in response - 2');
        }
      })
      .catch((response) => {
        //Error 
        console.log('Error in response - 3', response);
      });
  }


  componentDidMount() {

    this.loadCampaign();
  }

  render() {
    return (
      <Suspense fallback="loading...">
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <Layout
              campaignStatus={this.state.campaignStatus}
              count={this.state.count}
              message={this.state.message}
            />
          </BrowserRouter>
        </I18nextProvider>
      </Suspense>
    )
  }
}

export default App
