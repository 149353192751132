import React from 'react';
import Aux from '../../hoc/Auxiliare';

const banner = (props) => {

    let { campaignStatus, promotionCampaign } = props,
        status = campaignStatus,
        mobileImage = `/resources/images/alle/banner_main-mobile.png`,
        desktopImage = `/resources/images/alle/banner_main.png`;

    if (status === 'holding') {
        mobileImage = `/resources/images/alle/banner_holding-mobile.png`;
        desktopImage = `/resources/images/alle/banner_holding.png`;
    } else if (status === 'end') {
        
        mobileImage = `/resources/images/alle/banner_end-mobile.png`;
        desktopImage = `/resources/images/alle/banner_end.png`;
    }

    return (
        <Aux>
            <div className="banner__container separator__curve--banner-white">
                <div className="image__container">
                    <div className="image__img">
                        <picture>
                            <source srcSet={desktopImage} media="(min-width: 1024px)" />
                            <source srcSet={desktopImage} media="(min-width: 481px)" />
                            <source srcSet={mobileImage} media="(min-width: 200px)" />
                            <img src={desktopImage} alt="Milka Christmas" />
                        </picture>
                    </div>
                </div>
            </div>
        </Aux>
    );

}

export default banner;