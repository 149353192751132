import React from 'react';
import appGoogleTracking from '../../../modules/GoogleTracking';

const footercopyrightV1 = (props) => (
    <div className="footer__copyright_v1">
        <a 
            href={props.t('footer.link.mondelez')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_mondelez"
            data-category="Footer"
            data-action="Go to Page"
            data-label="ENG_ALLPAGE_FOOTER"
            onClick={appGoogleTracking.processEventCTA}
        >
            <span className="icon-mdlz"></span>
        </a>
            <h4>{props.t('footer.campanyName')} - <span className='text-small font'>{props.t('footer.campanyDesc')}</span></h4>
    </div>
);

export default footercopyrightV1;