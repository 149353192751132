import React, { Component } from 'react';
import '../styles/Confirmation.scss';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import TitleContent from '../../components/TitleContent/TitleContent';
import { mainLoaderToggle } from '../../modules/Helpers';
import Card from '../components/Card';

    class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        };
        this.resize = this.resize.bind(this);
    }


    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        mainLoaderToggle('hide');
    };

    render() {
        const { t } = this.props;
        const tagging1 = {
            event: "subscribe_newsletter",
            category: "Thank You Page",
            action: "Go to Page",
            label: "ENG_TY_OTHER"
        };

        const tagging2 = {
            event: "go_to_products",
            category: "Thank You Page",
            action: "Go to Page",
            label: "ENG_TY_OTHER"
        };

        const tagging3 = {
            event: "discover_mmax_milka",
            category: "Thank You Page",
            action: "Go to Page",
            label: "ENG_TY_OTHER"
        };

        return (
            <div>
                <TitleContent
                    heading={parse(t('Page.Confirm.title'))}
                    subheading={t('Page.Confirm.subTitle')}
                />

                <div className='miniature'>
                    <h3 className='title'>Entdecke jetzt dein liebstes Milka Produkt</h3>
                    <p className='descri'>Bei Milka findest du eine tolle Auswahl zartschmelzender Schokoladenprodukte zum Genießen, Teilen und Verschenken. Milka hat die passende Schokolade für jeden Anlass. Welches ist dein Milka Lieblingsprodukt?</p>
                    <picture>
                        <source srcSet="resources\images\alle\milka-sm.png" media="(max-width: 768px)" />
                        <img className='picture' src="resources\images\alle\milka.png" alt="Description of your image" />
                    </picture>
                </div>

                <Card t={t}></Card>



            </div>
        )
    }

}
export default withTranslation()(Confirmation);