import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { mainLoaderToggle } from "../modules/Helpers";

class TermsAndCond extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  displayFullVersion() {
    return (
      <>
        <div className="buzzer-terms">
          <div className="terms-container">
            <div className="s-content-copy">
              
              <div className="s-content-copy__item">

                <p className="headings sub">1. Teilnahmebedingungen</p>
                <p>
                  Veranstalterin der Milka Haselnusscreme Toast Cashback Aktion in Deutschland ist die Mondelez Deutschland GmbH, Konsul-Smidt-Straße 21, 28217 Bremen, Deutschland (nachstehend als „Mondelez Deutschland“ abgekürzt). Eine Teilnahme erfolgt ausschließlich nach Maßgabe der nachfolgend aufgeführten Teilnahmebedingungen und Datenschutzhinweisen. Mit der Teilnahme erkennt der Teilnehmer diese Teilnahmebedingungen ausdrücklich an.
                </p>
                <p>
                  Teilnahmeberechtigt sind natürliche Personen ab 18 Jahren, die vorab ein 350g oder 600g Glas Milka Haselnusscreme und ein Toastprodukt ihrer Wahl in einem Kaufakt kaufen sowie den Kassenbeleg auf <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a> hochladen.<br></br>
                  Es wird ein wöchentliches Kontingent geben, welches in Form eines Countdowns auf der Website zu sehen ist. Eine Teilnahme ist in der Woche nur möglich, wenn noch nicht alle „Rückzahlungen“ aufgebraucht sind.<br></br>
                  Der Teilnehmer erhält eine Bestätigung, sobald die Daten validiert wurden und es zur Auszahlung kommt.
                </p>
                <p>
                  Durch den Kauf des 350g oder 600g Glases Milka Haselnusscreme und ein beliebiges Toastprodukt im Zeitraum vom 01.07.2024 bis 29.09.2024 („Kaufzeitraum“) sowie durch das Hochladen eines Fotos des leserlichen Kassenbelegs auf <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a> bis zum 29.09.2024, wird dem oder der Gewinner*in bei gültiger Teilnahme und Angabe der Kontodaten (Kontoinhaber*in sowie IBAN) 2€ des Kaufpreises innerhalb von ca. 6 Wochen auf das angegebene Konto überwiesen abzüglich etwaiger Rabatte oder sonstiger Ermäßigungen. 
                </p>
                <p>
                  Auf dem Kassenbeleg müssen der Händler, das Kaufdatum sowie die Kaufsumme und das Aktionsprodukt deutlich lesbar sein. Die Veranstalterin behält sich eine Überprüfung des Kaufbelegs nach ihrem Ermessen vor. Nicht teilnehmende Produkte auf dem Kaufbeleg unkenntlich machen.
                </p>
                <p>
                  Der oder die Teilnehmer*in geht mit der Teilnahme „Milka Haselnusscreme Toast Cashback“ Aktion über die Teilnahme hinaus keine vertraglichen oder sonstigen rechtlichen Verpflichtungen gegenüber Mondelez Deutschland ein. Die Teilnahme dieser „Milka Haselnusscreme Toast Cashback“ Aktion unterliegt diesen Teilnahmebedingungen und Datenschutzbestimmungen.
                </p>
              </div>
              <div className="s-content-copy__item">
                <p className="headings sub">2. Teilnahmeberechtigung</p>
                <p>
                  Teilnahmeberechtigt sind ausschließlich natürliche Personen, die mindestens 18 Jahre alt sind und ihren Wohnsitz in der Bundesrepublik Deutschland haben sowie über ein Zahlungskonto in der Europäischen Union verfügen, das im SEPA-Zahlungsstandard erreichbar ist. Mitarbeiter*innen von Mondelez Deutschland und ihre Konzern- und Schwestergesellschaften sowie der eingebundenen Vertragspartner und ihrer unmittelbaren Familienangehörigen sind von einer Teilnahme an der Aktion ausgeschlossen.
                </p>
                <p>
                  Mondelez Deutschland behält sich vor, bei Verdacht einer Manipulation oder durch einen Verstoß gegen diese Teilnahmebedingungen oder andere unsachgemäße oder unfaire Mittel das Recht vor, Teilnehmer*innen auszuschließen und bereits erstattete Kaufpreiserstattungen wieder abzuerkennen bzw. zurückzufordern.
                </p>
                <p>
                  Alle Einsendungen müssen direkt von der Person vorgenommen werden, die sich an der Aktion beteiligt. Masseneinsendungen durch gewerbliche Absender, Verbrauchergruppen oder Dritte werden nicht akzeptiert. Unvollständige oder unleserliche Einsendungen, Einsendungen durch oder über Dritte oder Syndikate, Einsendungen durch Makros oder andere automatisierte Methoden (einschließlich programmierbare Teilnahme-Systeme) sowie Einsendungen, die den Vorgaben dieser Teilnahmebedingungen nicht vollumfänglich entsprechen, werden von der Teilnahme ausgeschlossen und nicht berücksichtigt. Stellt sich heraus, dass ein*e Teilnehmer*in einen oder mehrere Computer zur Umgehung dieser Bestimmungen nutzt, beispielsweise durch den Einsatz von „Skripten“, die „Brute-Force“-Methode, die Verschleierung seiner Identität durch Manipulation von IP-Adressen, willkürliches Erraten von Codes oder sonstige automatisierte Mittel, die die Anzahl seiner Einsendungen für diese Werbeaktion auf eine Weise erhöhen sollen, die nicht mit dem Geist dieser Werbeaktion im Einklang steht, werden die Einsendungen dieser Person von der Teilnahme ausgeschlossen und für ungültig erklärt.
                </p>
              </div>
              <div className="s-content-copy__item">
                <p className="headings sub">3. Ablauf der Aktion</p>
                <p>
                  Die „Milka Haselnusscreme Toast Cashback“-Teilnahme erfolgt zunächst durch einen Kauf des 350g oder 600g Glases Milka Haselnusscreme und einem beliebigen Toastprodukt in einem Kaufakt im Zeitraum vom 01.07.2024 bis 29.09.2024 („Kaufzeitraum“) sowie durch das Hochladen eines Fotos des leserlichen Kassenbelegs auf haselnusscreme.milka.de bis zum 29.09.2024.<br></br>
                  Es wird ein wöchentliches Kontingent für die 2€ Rückerstattungen geben, welches in Form eines Countdowns auf der Website zu sehen ist. Eine Teilnahme ist in der Woche nur möglich, wenn noch nicht alle „Rückzahlungen“ aufgebraucht sind. Mit der Teilnahme wird um die Angabe der Bankverbindung (IBAN) -zur Zahlung des Cashback Betrags von 2€- gebeten.<br></br>
                  Den Gewinnenden wird der Kaufpreis innerhalb von 6 Wochen auf das angegebene Konto überwiesen abzüglich etwaiger Rabatte oder sonstiger Ermäßigungen. 
                </p>
                <p>
                  Die getätigten Pflichtangaben in dem Online-Formular werden ausschließlich zur Rückererstattung des Kaufpreises und Abwicklung der Aktion verwendet. Für die Richtigkeit und Vollständigkeit der Angaben hat der oder die Teilnehmer*in Verantwortung zu tragen. Mondelez Deutschland behält sich das Recht vor, im Einzelfall den Kassenbeleg im Original anzufordern. Bitte das Original daher bis zum Abschluss der Aktion gut aufbewahren.
                </p>
                <p>
                  Die Teilnahme ist auf eine Person und für eine IBAN begrenzt. Jede*r Teilnehmer*in darf nur einmal teilnehmen. Die Teilnahme wird auf die Einmaligkeit der Kontodaten überprüft. Sollte eine Mehrfachteilnahme festgestellt werden, wird nur der Euro für den ersten hochgeladenen Kassenbon erstattet, es besteht kein weiterer Erstattungsanspruch. Jeder Kaufbeleg darf einmalig verwendet werden. Es darf nur eine Person des Haushaltes an der Aktion teilnehmen.
                </p>
              </div>
              <div className="s-content-copy__item">
                <p className="headings sub">4. Haftung</p>
                <p>
                  Für Datenverluste, insbesondere im Wege der Datenübertragung, und andere technische Defekte übernimmt Mondelez Deutschland keine Haftung.
                </p>
                <p>
                  Mondelez Deutschland haftet nicht für Schäden aufgrund von technischen Störungen, für Verzögerungen oder Unterbrechungen von Übertragungen oder für Schäden, die im Zusammenhang mit der Teilnahme an dem Gewinnspiel stehen, es sei denn Mondelez Deutschland handelt vorsätzlich oder grob fahrlässig. Hiervon unberührt bleiben etwaige Ersatzansprüche aufgrund von Produkthaftung und der Verletzung von Leben, Körper und Gesundheit sowie von wesentlichen Vertragspflichten. Im Fall der Verletzung von wesentlichen Vertragspflichten, d.h. solcher Pflichten, die die ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren Erfüllung der Nutzer vertrauen darf, ist die Haftung auf den vertragstypisch vorhersehbaren Schaden begrenzt.
                </p>
                <p>
                  Mondelez Deutschland haftet auch nicht für Produkte oder Dienstleistungen Dritter. Bei der Inanspruchnahme der Leistungen Dritter gelten deren Allgemeine Geschäftsbedingungen.<br></br>
                  Mondelez Deutschland haftet auch nicht im Falle einer Insolvenz des Dritten und leistet keinen Ersatz im Falle einer in der Zukunft liegenden, derzeit nicht absehbaren Eröffnung eines Insolvenzverfahrens über das Vermögen des Dritten.
                </p>
                <p>
                  Mondelez Deutschland übernimmt keine Verantwortung für verspätet eingehende, unvollständige, inkorrekt eingesandte, schadhafte oder falsch zugestellte Einsendungen, Ansprüche oder Mitteilungen, unabhängig davon, ob diese durch Fehler, Unterlassung, Veränderung, Manipulation, Löschung, Diebstahl, Zerstörung, Übertragungsunterbrechung, Kommunikationsausfall oder anderweitig bedingt wurden. 
                </p>
                <p>
                  Die Teilnehmer*innen erkennen durch ihre Teilnahme an, dass die Beteiligung an dieser Werbeaktion auf alleiniges Risiko des Teilnehmers erfolgt. Die Veranstalterin, ihre Organmitglieder, Direktor, Beschäftigten und Agenten geben, soweit rechtlich zulässig, keinerlei Garantien oder Zusicherungen über die Richtigkeit oder Vollständigkeit der Inhalte auf <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a> und auf mit dieser Website verlinkten Seiten ab und übernehmen keine Haftung oder Verantwortung für:
                </p>
                <p>a. Fehler, Irrtümer oder Ungenauigkeiten von Inhalten;</p>
                <p>b. Sachschäden jeglicher Art, die aus der Teilnahme an der Werbeaktion herrühren;</p>
                <p>c. Todesfälle oder Personenschäden, die sich als direkte Folge der Teilnahme an der Werbeaktion ergeben, sofern diese nicht durch zumindest Fahrlässigkeit von Mondelez International, seinen Organmitgliedern, Direktoren, Beschäftigten und Agenten verursacht werden;</p>
                <p>d.  Unterbrechungen oder Abbrüche der Übertragung an oder von <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a>;</p>
                <p>e. Softwarefehler, Viren usw., die an oder durch <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a> an einen Dritten übertragen werden, und/oder </p>
                <p>f. Verluste oder Schäden jeglicher Art, die sich aus der Nutzung geposteter, per E-Mail versandter, übertragener oder anderweitig über <a href="https://haselnusscreme.milka.de" target="_blank">haselnusscreme.milka.de</a> zur Verfügung gestellter Inhalte ergeben,</p>
                <p>g. Datenverluste, insbesondere auf dem Weg der Datenübertragung.</p>
                <p>Gewinne können nicht gegen Bargeld oder sonstige Formen der Entschädigung eingelöst werden, sofern nichts anderes angegeben wurde. Falls der Nutzer oder die Nutzerin einen Teil eines Gewinns aus irgendeinem Grund nicht innerhalb der von der Veranstalterin vorgegebenen Zeitrahmens einlöst, verfällt dieses Gewinnelement für den Nutzer oder Nutzerin und es wird keine Geldzahlung oder sonstige Form von Entschädigung als Ersatz für dieses Element geliefert, soweit nichts anderes vereinbart wurde. Für den Fall, dass der Gewinn aus unvorhersehbaren Gründen nicht zur Verfügung steht, behält sich die Veranstalterin das Recht vor, es nach alleinigem Ermessen durch einen anderen gleichartigen Gewinn zu ersetzen oder ganz zu streichen.</p>
              </div>
              <div className="s-content-copy__item">
                <p className="headings sub">5. Vorzeitige Beendigung des Gewinnspiels /Änderung der Teilnahmebedingungen</p>
                <p>
                  Mondelez Deutschland behält sich vor, dieses Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von weiteren Gründen abzubrechen oder zu beenden, insbesondere bei Infizierung durch Computerviren, Netzwerkausfällen, Softwarefehlern, Manipulationen, unbefugten Eingriffen, Betrug, technischem Versagen oder Ursachen außerhalb der Kontrolle des Veranstalters, die die Verwaltung, Sicherheit, Fairness, Integrität oder ordnungsgemäße Durchführung dieser Werbeaktion stören oder beeinträchtigen. Mondelez Deutschland kann diese Bedingungen jederzeit und ohne weitere Benachrichtigungen ändern.
                </p>
              </div>
              <div className="s-content-copy__item">
                <p className="headings sub">6. Support/Kontakt/Hinweise zur Streitbeilegung</p>
                <p>Sämtliche Fragen, Kommentare oder Beschwerden zu der „Milka Haselnusscreme Toast Cashback“ Aktion sind an <a href="mailto:verbraucherservice@mdlz.com" target="_blank">verbraucherservice@mdlz.com</a> zu richten. Fernmündlich mitgeteilte oder verspätete Beschwerden können nicht bearbeitet werden.</p>
                <p>Informationen zur Online-Streitbeilegung gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG):</p>
                <p>Die EU-Kommission stellt eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) bereit. Die OS-Plattform soll als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen, dienen. Die OS-Plattform ist unter folgendem Link erreichbar <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr.</a></p>
                <p>Wir nehmen derzeit nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Sie können sich aber jederzeit mit Ihrem Anliegen an unseren Verbraucherservice unter <a href="mailto:verbraucherservice@mdlz.com" target="_blank">verbraucherservice@mdlz.com</a> wenden.</p>
              </div>


              <div className="s-content-copy__item">
                <p className="headings sub">7. Sonstiges</p>
                <p>
                  Mondelez Deutschland behält sich das Recht vor die „Milka Haselnusscreme Toast Cashback“ Aktion jederzeit und ohne Angaben von Gründen einzustellen, abzubrechen, auszusetzen oder zu verändern.                </p>
                <p>
                  Sollten einzelne Bestimmungen dieser Teilnahmebedingungen unwirksam, unzulässig oder undurchführbar sein oder werden, so lässt dies die Wirksamkeit der Teilnahmebedingungen im Übrigen unberührt. An die Stelle der unwirksamen, unzulässigen oder undurchführbaren Klausel treten Regelungen, deren Wirkungen der wirtschaftlichen Zielsetzung möglichst nahekommen. Dies gilt jedoch nur dann, wenn das Festhalten an dem gesamten Vertrag, auch unter Berücksichtigung dieser vorgesehenen Änderung, nicht eine unzumutbare Härte für eine Vertragspartei darstellen würde. Etwaige Rechte aus dem Vertragsverhältnis, das diesen Teilnahmebedingungen zugrunde liegt, sind für Sie nicht auf Dritte übertragbar.
                </p>
                <p>
                  Mondelez Deutschland kann diese Bedingungen jederzeit und ohne weitere Benachrichtigungen ändern. Der Rechtsweg ist ausgeschlossen.
                </p>
                <p>
                  Für Teilnehmer*innen aus Deutschland gilt: Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des Internationalen Privatrechts. Der Rechtsweg ist ausgeschlossen.
                </p>
                <p>
                  Die Aktion steht in keiner Verbindung zu Instagram oder Facebook und wird in keiner Weise von Instagram oder Facebook gesponsert, unterstützt oder organisiert. Instagram und Facebook sind für keinen der im Rahmen des Gewinnspiels veröffentlichten Beiträge und Inhalte verantwortlich. Empfänger der durch die Teilnehmer bereitgestellten Informationen sind nicht die genannten Plattformen, sondern Mondelez. Mondelez stellt die genannten Plattformen von jeglichen Ansprüchen Dritter im Zusammenhang mit diesem Gewinnspiel frei. Sämtliche Fragen, Kommentare oder Beschwerden zum Gewinnspiel sind nicht an diese Plattformen zu richten, sondern an Mondelez unter <a href="mailto:verbraucherservice@mdlz.com" target="_blank">verbraucherservice@mdlz.com.</a>
                </p>
              </div>


              <div className="s-content-copy__item">
                <p className="headings sub">
                  8.  Datenschutzhinweis für diese Aktion
                </p>
                <p>
                  Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen dieser „Milka Haselnusscreme Toast Cashback“ Aktion ist die Mondelez Deutschland GmbH, Konsul-Smidt-Straße 21, 28217 Bremen. Mit der Durchführung der Aktion ist die folgende Agentur beauftragt: Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg. Alle beteiligten Agenturen unterliegen sämtlichen anwendbaren Datenschutzgesetzen. Sie sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die Anforderungen entsprechender Verträge gebunden. Eine darüberhinausgehende Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nicht.
                </p>
                <p>Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung dieser Aktion (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. f) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO). Dabei werden nur die Daten der erfolgreichen Teilnehmer*innen, die das 350g oder 600g Glas Milka Haselnusscreme gekauft und ein beliebiges Toastprodukt sowie einen gültigen Kassenbeleg hochgeladen haben, erfasst. Die Datenerfassung betrifft die E-Mailadresse, den Namen und die Bankdaten dieser Teilnehmer*innen. Die Daten werden ausschließlich zur Verifizierung der Teilnahme/Gewinner*innen gespeichert.</p>
                <p>
                  Sie können Ihre Einwilligung in die Nutzung und Speicherung Ihrer personenbezogenen Daten jederzeit ohne Angabe von Gründen widerrufen. Hierzu genügt eine formlose E-Mail an <a href="mailto:verbraucherservice@mdlz.com" target="_blank">verbraucherservice@mdlz.com.</a> Im Falle eines Widerrufs ist eine weitere Teilnahme an der Aktion nicht mehr möglich. 
                </p>
                <p>Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem die Aktion abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der Daten der nicht rechtzeitig erfolgten Teilnahmen, sei es, weil sie nicht zu den insgesamt 70.000 Gewinner*innen gehörten oder weil sie nach dem Kaufzeitraum hochgeladen wurden. Die Daten der Personen, die erfolgreich an der Aktion teilgenommen haben, sind aus gesetzlichen und buchhalterischen Gründen aufzubewahren. Für diese Speicherung dieser Daten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt. Weitere Informationen zu dem Umgang von Mondelez Deutschland mit Ihren personenbezogenen Daten und zu Ihren gesetzlichen Rechten und wie sie diese ausüben können, entnehmen Sie bitte unserer Datenschutzerklärung DE.</p>
                <p>
                  Die Webseiten unter www.facebook.com und www.instagram.com und die auf dieser Seite vorgehaltenen Dienste werden angeboten von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Es kann nicht ausgeschlossen werden, dass Facebook die hochgeladenen Daten selbstständig speichert und verarbeitet.<br></br>
                  Wir haben keinen Einfluss auf den Umfang der Erhebung und die weitere Verwendung dieser Daten durch Facebook. Informationen des Drittanbieters zum Datenschutz können Sie der nachfolgenden Webseite von Facebook und Instagram entnehmen: 
                </p>
                <p><a href="https://www.facebook.com/about/privacy" target="_blank">https://www.facebook.com/about/privacy</a></p>
                <p><a href="https://help.instagram.com/519522125107875" target="_blank">https://help.instagram.com/519522125107875</a></p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return this.displayFullVersion();
  }
}

export default withTranslation()(TermsAndCond);
