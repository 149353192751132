import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';               //Routing component - to display the corresponding containers
import GLOBAL_CONFIG from '../config/Config';		            //Global Settings that contains URL etc
//import AsyncComponent from '../../hoc/AsyncComponent';       //Async loading of components    
import MetaHeader from '../components/Content/MetaHeader/MetaHeader';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import Banner from '../components/Layout/Banner';
import { withTranslation } from 'react-i18next';
/*
    All website pages as container
*/
// import Holding from '../pages/Holding/Holding';                   //Holding page content
import Holding from '../toast/pages/Holding';                    //FAQ page content
import StartMitmachen from '../toast/pages/StartMitmachen';
import End from '../toast/pages/End';
import Confirmation from '../toast/pages/Confirmation';
import TermsAndCond from '../page/TermsAndCond';
import Cookie from '../toast/pages/Cookie';
import Faq from '../toast/pages/Faq';
// import HomeNasher from '../pages/Home/Home';


// Use layout as the main wrapper for our app
class Layout extends Component {

  componentDidMount() {
  }
  render() {
    //Campaign is assign
    let RouteRender = null;
    const { count, t } = this.props;
    console.log('test', this.props);


    //Routing check for 
    if (this.props.campaignStatus === 'main') {
      RouteRender = (
        <Switch>
        
          <Route
            exact
            path="/"
            render={(props) => <StartMitmachen {...props} count={count} campaignStatus={this.props.campaignStatus} message={this.props.message} />}
          />
          <Route
            exact
            path={GLOBAL_CONFIG.Route.participation}
            render={(props) => <StartMitmachen {...props} count={count} campaignStatus={this.props.campaignStatus} message={this.props.message} />}
          />
          <Route
            exact
            path={GLOBAL_CONFIG.Route.faq}
            render={(props) => <Faq />}
          />
          <Route
            exact
            path={GLOBAL_CONFIG.Route.terms}
            render={(props) => <TermsAndCond {...props} count={count} />}
          />
          <Route path={GLOBAL_CONFIG.Route.thankyou} exact component={Confirmation} />

          <Route
            path={GLOBAL_CONFIG.Route.cookie}
            exact
            render={(props) => <Cookie {...props} />}
          />
        </Switch>
      );
    } else if (this.props.campaignStatus === 'holding') {
      RouteRender = (
        <Switch>
          <Route path="/" exact component={Holding} />
          <Route
            path={GLOBAL_CONFIG.Route.cookie}
            exact
            render={(props) => <Cookie {...props} />}
          />
          <Route
            exact
            path={GLOBAL_CONFIG.Route.faq}
            render={(props) => <Faq />}
          />
          <Route component={Holding} />
        </Switch>
      );

    } else if (this.props.campaignStatus === 'end') {
      RouteRender = (
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <End {...props} campaignStatus={this.props.campaignStatus} />}
          />
          <Route
            exact
            path={GLOBAL_CONFIG.Route.faq}
            render={(props) => <Faq />}
          />
          <Route
            path={GLOBAL_CONFIG.Route.cookie}
            exact
            render={(props) => <Cookie {...props} />}
          />
        </Switch>
      );

    }


    //Master page - that contains all routes for the different pages / containers
    return (
      <>
        <MetaHeader promotionCampaign={this.props.promotionCampaign} />
        <Header t={t} />
        <main className="main-content " style={{ backgroundImage: `url(/resources/images/Pages/bg-pattern/bg-paper-beige.jpg)` }}>
          {
            window.location.pathname==='/faq' ? null :<Banner campaignStatus={this.props.campaignStatus} />
          }
          
          {RouteRender}
        </main>
        <Footer t={t} />
      </>
    );
  }
}
export default withTranslation()(Layout);
