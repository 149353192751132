import i18n from 'i18next';
import axios from 'axios';

let CONTENT__ALL = null;

const getLocalisation = async (jsonPath) => {
  try {
    let response = await axios.get(jsonPath)
    return response;
  } catch (e) {
    return 'error';
  }
}

const initLocalisation = () => {
  i18n.init({
    lng: 'en',
    resources: {
      en: CONTENT__ALL
    },
    fallbackLng: 'en',
    debug: false,
    ns: ["data"],
    defaultNS: "data",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    cache: {
      enable: false
    },
    react: {
      wait: false
    }
  });
}

export const buildLocalisedContent = async () => {
  try {
    CONTENT__ALL = await getLocalisation('resources/localisation/de.json');
    initLocalisation();
  } catch (e) {
    console.error(e);
  }
}

export default i18n;