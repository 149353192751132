import React, { useState } from 'react'
import './FirstContent.scss'

export default function FirstContent(isMobile) {

    return (
        <div className='container_firstContent'>
            <div className="first">
                <p className='description'>Du möchtest die Milka Haselnusscreme testen & genießen? Ab dem 01.07.2024 sucht Milka insgesamt 50.000 Tester, die ein zartes Frühstück genießen möchten.
                    In Kürze ganz einfach die Milka Haselnusscreme testen und 2€ zurückerhalten:
                    <br /><br />
                    <p className='nocenter description'>
                        1. Milka Haselnusscreme sowie ein Toastbrot deiner Wahl kaufen<br />
                        2. Kaufbeleg hochladen<br />
                        3. Du erhältst 2€ vom Kaufpreis zurück<br />
                    </p>
                </p>
            </div>
            <div className="second">
                <picture className='firstCircle'>
                    <source srcSet="resources\images\alle\smallProduct.png" media="(max-width: 768px)" />
                    <img className='imgFirstCntent' src="resources\images\alle\bigProduct.png" alt="Description of your image" />
                </picture>
            </div>

        </div>
    )
}
